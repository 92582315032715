<template>
	<v-app :class="{ embeded: embed }">
		<ConfirmDialog ref="confirm"></ConfirmDialog>
		<DangerZone ref="dangerzone"></DangerZone>
		<FileUploadDialog ref="fileUploadDialog"></FileUploadDialog>
		<NoAccount ref="noAccount"></NoAccount>
		<ToastDialog ref="toast"></ToastDialog>
		<ShowTheCode ref="showTheCode"></ShowTheCode>
		<Notification ref="notification"></Notification>
		<FreeTrialEndedDialog ref="freeTrialEndedDialog"></FreeTrialEndedDialog>
		<TrialTopBar
			v-if="(isActiveTrial && !isSubscriptionActive) || (isEndedTrial && !isSubscriptionActive)"
			:isEndedTrial="isEndedTrial"
			:trialDaysRemaining="trialDaysRemaining"
			:isTrialCCNow="isTrialCCNow"
			v-on:upgrade="upgrade()">
		</TrialTopBar>

		<!--<InactiveSubscriptionDialog 
      @activateSubscription="activateSubscription"
      @logout="logout"
      v-if="!isTrial && !isSubscriptionActive"
      :isSubscriptionOwner="isSubscriptionOwner">
    </InactiveSubscriptionDialog>-->

		<!--<ExpiredTrialDialog 
      @upgrade="upgrade()"
      @logout="logout"
      v-if="isTrial && isEndedTrial && this.$route.name != 'subscription'"
      :isSubscriptionOwner="isSubscriptionOwner">
    </ExpiredTrialDialog>-->

		<v-snackbar
			v-model="maintenanceInformation.visible"
			:color="maintenanceInformation.color"
			:multi-line="maintenanceInformation.mode === 'multi-line'"
			:timeout="maintenanceInformation.timeout"
			:top="maintenanceInformation.position === 'top'"
			class="maintenance">
			<div>
				<h3 v-if="maintenanceInformation.title">
					{{ maintenanceInformation.title }}
					<v-btn
						v-if="maintenanceInformation.timeout === -1"
						icon
						@click="maintenanceInformation.visible = false">
						<v-icon>icon-close-medium</v-icon>
					</v-btn>
				</h3>
				<p v-if="maintenanceInformation.text">
					{{ maintenanceInformation.text }}
				</p>
			</div>
		</v-snackbar>

		<v-snackbar
			v-model="pluginInstallation.visible"
			:color="pluginInstallation.color"
			:multi-line="pluginInstallation.mode === 'multi-line'"
			:timeout="pluginInstallation.timeout"
			:top="pluginInstallation.position === 'top'">
			<v-layout align-center>
				<v-icon class="pr-3" dark large>{{ pluginInstallation.icon }}</v-icon>
				<v-layout column>
					<div>
						<strong>{{ pluginInstallation.title }}</strong>
					</div>
					<div>
						{{ pluginInstallation.text }}
						<br />
						<span v-if="pluginInstallation.showAction">
							<a
								style="color: white; text-decoration: underline"
								:href="pluginInstallation.href"
								@click.stop.prevent="connectAccount(pluginInstallation.href)"
								>Connect your account.</a
							>
						</span>
					</div>
				</v-layout>
				<v-btn v-if="pluginInstallation.timeout === -1" icon @click="pluginInstallation.visible = false">
					<v-icon>icon-close-medium</v-icon>
				</v-btn>
			</v-layout>
		</v-snackbar>

		<v-app-bar app clipped-left clipped-right absolute elevation="0" v-if="appBar">
			<a href="/"><img style="height: 36px" src="/assets/ls-contact-3d-color-logo.png" /></a>
			<v-spacer></v-spacer>

			<v-btn rounded small outlined elevation="0" v-if="deferredPrompt && authenticated()" @click="install">
				{{ isDesktop ? "Add to Desktop" : "Add to Home" }}
			</v-btn>

			<v-menu min-width="250" max-width="250" bottom :offset-y="true">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-show="!isDesktop" v-bind="attrs" v-on="on">
						<v-icon size="24">icon-circle-help-stroke</v-icon>
					</v-btn>
				</template>
				<v-list dense>
					<v-list-item @click="showHelp(false)">
						<v-list-item-title>Getting Started Guide</v-list-item-title>
					</v-list-item>
					<v-list-item @click="showHelp(true)">
						<v-list-item-title>Contact Support</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-btn rounded elevation="0" color="#dff0ff" v-if="allowProjectPage" @click="redirectToHome">Sign In</v-btn>

			<div v-if="authenticated()" style="width: 8px"></div>
			<v-menu
				transition="slide-y-transition"
				bottom
				:offset-y="true"
				v-model="profileShown"
				min-width="250"
				max-width="250"
				content-class="profile-menu">
				<template v-slot:activator="{ on, attrs }">
					<div
						v-if="authenticated()"
						v-bind="attrs"
						v-on="on"
						style="cursor: pointer"
						@click="profileShown = !profileShown"
						class="d-flex">
						<v-avatar size="24" class="text-uppercase text-white">
							{{ userInitials }}
						</v-avatar>
						<span class="text-truncate profile-name ml-2" v-if="!mini">
							{{ displayName }}
						</span>
						<v-icon v-if="!mini">{{ profileShown ? "icon-chevron-up" : "icon-chevron-down" }}</v-icon>
					</div>
				</template>
				<v-list dense class="pa-4 profile-list">
					<v-list-item class="profile-user-details pa-0">
						<v-avatar size="40" class="text-uppercase text-white avatar">
							{{ userInitials }}
						</v-avatar>
						<v-list-item-content class="ml-2">
							<v-list-item-title>
								<h6 class="text-truncate">
									{{ displayName }}
								</h6>
							</v-list-item-title>
							<v-list-item-subtitle class="text-truncate">
								{{ organizationName }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider></v-divider>
					<div v-for="(item, i) in profileItems" :key="i">
						<template v-if="item.text === 'Settings' && !allowSettingsPage">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on">
										<v-list-item class="not-allowed-profile-item pa-0">
											<v-list-item-icon>
												<v-icon v-text="item.icon" :style="item.iconStyle"></v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>
													{{ item.text }}
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</div>
								</template>
								<span>{{ constants.PREMIUM_MESSAGE_ADMIN }}</span>
							</v-tooltip>
						</template>
						<v-list-item
							v-else-if="!item.subItems && shouldShow(item.permission) && item.shouldShow()"
							@click="nav(item.to())"
							class="pa-0">
							<v-list-item-icon>
								<v-icon v-text="item.icon" :style="item.iconStyle"></v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>
									{{ item.text }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider v-if="item.subItems && item.subItems.length > 1"></v-divider>
						<v-menu
							v-model="switchOrgs"
							left
							:offset-x="true"
							content-class="profile-menu-submenu"
							v-if="item.subItems && item.subItems.length > 1">
							<template v-slot:activator="{ on, attrs }">
								<v-list-item @click="switchOrgs = !switchOrgs" v-bind="attrs" v-on="on" class="pa-0">
									<v-list-item-icon><v-icon v-text="item.icon"></v-icon></v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title v-text="item.text"></v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>

							<v-list dense>
								<v-list-item v-for="(item, i) in organizations()" :key="i" @click="switchToOrg(item)">
									<v-list-item-title class="action">{{ item.name }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						<v-divider v-if="item.subItems && item.subItems.length > 1"></v-divider>
					</div>

					<div class="menu-footer">
						<v-list-item @click="logout" class="pa-0">
							<v-list-item-icon>
								<v-icon>icon-door-open</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Sign Out</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
					<v-divider></v-divider>
					<div class="menu-footer-links">
						<v-btn elevation="0" @click="showPrivacyPolicy"> Privacy Policy </v-btn>
					</div>
				</v-list>
			</v-menu>
		</v-app-bar>

		<v-expand-x-transition appear>
			<v-navigation-drawer
				class="primary-nav"
				app
				permanent
				clipped
				absolute
				:mini-variant="mini"
				width="200"
				v-if="navigation && authenticated() && showLeftNav()"
				v-model="navigation">
				<v-list dense>
					<v-list-item
						v-for="item in navigationItems.filter((x) => (x.shouldShow ? x.shouldShow() : true))"
						:key="item.to"
						:to="item.to">
						<v-tooltip right :disabled="true">
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
							</template>
							<span>{{ item.text }}</span>
						</v-tooltip>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
				<v-divider />
				<v-list dense>
					<v-list-item to="/apps" v-if="marketplaceEnabled()">
						<v-icon>icon-puzzle-piece-solid</v-icon>
						<v-list-item-title>Apps</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-menu min-width="250" max-width="250">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on" style="display: flex; cursor: pointer; width: 100%">
									<v-icon>icon-circle-help-stroke</v-icon>
									<v-list-item-title>Help</v-list-item-title>
								</div>
							</template>
							<v-list dense>
								<v-list-item @click="showHelp(false)">
									<v-list-item-title>Getting Started Guide</v-list-item-title>
								</v-list-item>
								<v-list-item @click="showHelp(true)">
									<v-list-item-title>Contact Support</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-list-item>
					<v-list-item @click="showReferralPage">
						<v-icon>icon-user-rotation</v-icon>
						<v-list-item-title>Refer a Friend!</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>
		</v-expand-x-transition>

		<router-view v-if="attributesEnabled()" name="inner" />

		<v-bottom-navigation app dense v-if="navigation && authenticated() && showBottomNav()">
			<v-btn
				style="height: 100%; width: 100%"
				color="transparent"
				v-for="item in navigationItems.filter((x) => x.mobileEnabled)"
				:key="item.to"
				:to="item.to">
				<span>{{ item.text }}</span>

				<v-icon>{{ item.mobileIcon }}</v-icon>
			</v-btn>
		</v-bottom-navigation>

		<v-main class="main">
			<v-snackbar v-model="error" v-if="error" top>
				{{ error.message }}
				<template v-slot:action="{ attrs }">
					<v-btn color="primary" text v-bind="attrs" @click="error = null"> Close </v-btn>
				</template>
			</v-snackbar>

			<BlockedUser v-if="blockedUser()" />
			<ProjectPage v-else-if="allowProjectPage" :project-id="projectId" :share-id="shareId" />
			<LoadingPage v-else-if="!authenticated()" />

			<v-container
				v-if="!allowProjectPage"
				class="align-stretch shell"
				:class="areButtonsSticky ? 'bottom-back-buttons' : ''"
				fill-height
				fluid>
				<router-view v-if="authenticated()"></router-view>
			</v-container>

			<v-overlay :value="isFABOpen" />
			<ActionFAB v-if="authenticated()" @change="fabChanged" />
			<ContactSupportDialog ref="contactSupportDialog"></ContactSupportDialog>
		</v-main>
	</v-app>
</template>

<script>
	import DangerZone from "./components/DangerZone";
	import ToastDialog from "./components/ToastDialog.vue";
	import crypto from "crypto-js/aes";
	import ActionFAB from "./components/ActionFAB.vue";
	import FileUploadDialog from "./components/FileUploadDialog.vue";
	import InactiveSubscriptionDialog from "./components/InactiveSubscriptionDialog.vue";
	import LoadingPage from "./components/LoadingPage";
	import ProjectPage from "./components/ProjectPage.vue";
	import BlockedUser from "./components/BlockedUser";
	import ConfirmDialog from "./components/ConfirmDialog.vue";
	import ShowTheCode from "./components/ShowTheCode.vue";
	import TrialTopBar from "./components/TrialTopBar.vue";
	import api from "./api";
	import parser from "./helpers/parser";
	import Account from "./api/account";
	import ExpiredTrialDialog from "./components/ExpiredTrialDialog.vue";
	import DeviceUtility from "./helpers/device";
	import { EventBus } from "./helpers/eventBus";
	import installPrompt from "./helpers/installPrompt";
	import ContactSupportDialog from "./components/ContactSupportDialog.vue";
	import FormEditor from "./components/Forms/FormEditor.vue";
	import AttributesPanel from "./components/AttributesPanel.vue";
	import FreeTrialEndedDialog from "./components/FreeTrialEndedDialog.vue";
	import { hasFreeTrialEnded } from "./helpers/hasFreeTrialEnded";
	import { constants } from "./api/constants";
	import { isRoleOrInRoles, Role } from "@/enums/Role";
	import * as Sentry from "@sentry/browser";
	import StorageWrapper from "@/helpers/storage";
	export default {
		name: "App",
		components: {
			DangerZone,
			ToastDialog,
			ActionFAB,
			FileUploadDialog,
			LoadingPage,
			ProjectPage,
			BlockedUser,
			ConfirmDialog,
			ShowTheCode,
			TrialTopBar,
			ContactSupportDialog,
			InactiveSubscriptionDialog,
			ExpiredTrialDialog,
			FormEditor,
			FreeTrialEndedDialog,
		},
		data: () => {
			const params = new Proxy(new URLSearchParams(window.location.search), {
				get: (searchParams, prop) => searchParams.get(prop),
			});
			const embed = params.embed == "true";
			return {
				loading: true,
				appBar: params.hideAppBar == "true" || embed ? false : true,
				navigation: params.hideNavigation == "true" || embed ? false : true,
				embed: embed,
				constants: constants,
				pluginInstallation: {
					color: "primary",
					icon: "icon-info-circle-stroke",
					mode: "multi-line",
					position: "top",
					timeout: -1,
					title: "Integration Activated",
					text: "",
					visible: false,
					showAction: true,
				},
				me: null,
				switchOrgs: false,
				account: null,
				profileShown: false,
				error: null, // { message: 'an error'},
				maintenanceInformation: {
					color: "primary",
					position: "top",
					timeout: -1,
					visible: false,
					title: "",
					text: "",
					mode: "multi-line",
				},
				isTrial: false,
				isTrialCCNow: false,
				isActiveTrial: false,
				isEndedTrial: false,
				trialDaysRemaining: 10,
				isFABOpen: false,
				isSubscriptionActive: true,
				isSubscriptionOwner: false,
				deferredPrompt: null,
				isDesktop: true,
				allowProjectPage: false,
				allowSettingsPage: true,
				projectId: "",
				shareId: "",
			};
		},
		created() {
			window.addEventListener("beforeinstallprompt", (e) => {
				e.preventDefault();
				this.deferredPrompt = e;
				installPrompt.setPrompt(e);

				try {
					StorageWrapper.removeItem("AppInstalled");
				} finally {
					// Do nothing
				}
			});

			window.addEventListener("appinstalled", () => {
				this.deferredPrompt = null;
				installPrompt.setPrompt(null);

				EventBus.$emit("AppInstalled");

				try {
					StorageWrapper.setItem("AppInstalled", "true");
				} catch (err) {
					console.error(err);
				}

				Sentry.captureMessage("AppInstalled");
			});

			EventBus.$on("InstallApp", async () => {
				console.log("Install app prompt");
				await this.install();
			});
		},
		async errorCaptured(err, vm, info) {
			if (err && err.message) {
				this.error = err;
				console.error(err);
			}
		},
		async mounted() {
			this.isDesktop = DeviceUtility.isDesktop;

			try {
				this.$root.$confirm = this.$refs.confirm;
				this.$root.$dangerzone = this.$refs.dangerzone;
				this.$root.$noAccount = this.$refs.noAccount;
				this.$root.$toast = this.$refs.toast;
				this.$root.$showTheCode = this.$refs.showTheCode;
				this.$root.$notification = this.$refs.notification;
				this.$root.$freeTrialEndedDialog = this.$refs.freeTrialEndedDialog;
				this.$root.$fileUploadDialog = this.$refs.fileUploadDialog;
				this.loading = true;
				this.maintenanceInformation.color = window.env.VITE_MAINTENANCE_MODE_COLOR;
				this.maintenanceInformation.title = window.env.VITE_MAINTENANCE_MODE_TITLE;
				this.maintenanceInformation.text = window.env.VITE_MAINTENANCE_MODE_TEXT;
				this.maintenanceInformation.visible = window.env.VITE_MAINTENANCE_MODE_ACTIVE === "true";
				try {
					this.me = await api.getMe(true, true, true);
				} catch (err) {
					if (
						window.location.pathname.indexOf("/projects/") !== -1 &&
						window.location.search.indexOf("shareId") !== -1
					) {
						this.loading = false;
						this.allowProjectPage = true;
						const url = window.location.href;
						this.projectId = url.split("/projects/")[1].split("?")[0];
						this.shareId = url.split("shareId=")[1].split("&")[0];
						return;
					}
				}
				await this.storeUserAccount();
				this.storeUser();
				this.possiblyShowPluginInstallations();
				this.allowSettingsPage = !(await hasFreeTrialEnded(this.me.organization));
				this.loading = false;
			} catch (error) {
				console.error("Error initializing app", error);
				// if user doesnt exist, we don't want to log it to Sentry
			}
		},
		methods: {
			redirectToHome() {
				this.nav(`/projects/${this.projectId}/${this.shareId}`);
			},
			async dismiss() {
				this.deferredPrompt = null;
			},
			async install() {
				this.deferredPrompt?.prompt();
			},
			activateSubscription() {
				const email = encodeURIComponent(this.encrypt(JSON.parse(StorageWrapper.getItem("user")).email));
				const organizationId = encodeURIComponent(StorageWrapper.getItem("organization_id"));
				window.location.href = `${
					window.env.VITE_PAY_DOMAIN
				}?skipAccountCreation=true&identityToken=${StorageWrapper.getItem(
					"platform_identity_token"
				)}&tenantId=${organizationId}&email=${email}`;
			},
			upgrade() {
				// This is coming soon
				if (window.env.VITE_ENABLE_PURCHASE_MODULE == "false") {
					window.open(
						"mailto:" +
							window.env.VITE_SUPPORT_EMAIL +
							"?subject=" +
							encodeURIComponent("Subscription Request") +
							"&body=" +
							encodeURIComponent("I would like to subscribe to LiveSwitch Contact.")
					);
				} else {
					this.nav(this.subscriptionUrl);
				}
			},
			encrypt(value) {
				return crypto.encrypt(value.trim(), window.env.VITE_CONTACT_ENCRYPTION_KEY).toString();
			},
			async storeAndRefresh() {
				this.storeUserAccount();
				window.location.href = "";
			},
			async storeUserAccount() {
				// load self - true means include permissions for the user
				this.me = await api.getMe(true, true, true);

				// this account wrapper matches up to what we have
				// on the server so we can query permissions easily
				this.account = new Account(this.me.account);
				StorageWrapper.setItem("account", JSON.stringify(this.me.account));
				if (this.me.account) {
					/*const response  = await api.createPlatformIdentity(this.me.account.userId);
        			api.setPlatformIdentityToken(response?.token);*/
					//StorageWrapper.setItem('pit_response', response);
					//StorageWrapper.setItem('platform_identity_token', response?.token);
					//const status = this.getSubscriptionStatus(response.applicationId, response.applications);

					// we also have organization.isTrial - that is used to indicate
					// that this org has had a trial at some point
					// and NOT to indicate if that trial is currently active or not
					this.isTrial = this.me.organization.isTrial;
					this.isTrialCCNow = this.me.organization.isTrialCCNow;

					this.isActiveTrial = this.me.organization.isActiveTrial;
					this.trialDaysRemaining = this.me.organization.trialDaysRemaining;
					this.isEndedTrial = this.me.organization.isEndedTrial;

					// paid account info
					this.isSubscriptionActive = this.me.organization.isSubscriptionActive;
					this.isSubscriptionOwner = this.me.account.isSubscriptionOwner;

					try {
						window.Appcues.identify(this.me.account.id, {
							// recommended (optional) properties
							createdAt: new Date(this.me.account.createdAt).getTime(), // Unix timestamp of user signup date

							role: this.me.account?.roles[0]?.name, // Current user’s role or permissions
							accountId: this.me.organization?.id, // Current user's account ID
							firstName: this.me.user.firstName,
							isTrial: this.isTrial,
							isTrialCCNow: this.isTrialCCNow,

							isActiveTrial: this.isActiveTrial,
							trialDaysRemaining: this.trialDaysRemaining,
							isEndedTrial: this.isEndedTrial,

							isSubscriptionActive: this.isSubscriptionActive,
							isSubscriptionOwner: this.isSubscriptionOwner,

							// additional suggestions
							companyName: this.organizationName, // Current user’s company name
							email: this.me.user.email, // Current user's email
						});

						if (this.me.organization) {
							window.Appcues.group(this.me.organization.id, {
								// example properties
								createdAt: new Date(this.me.organization.createdAt).getTime(), // Unix timestamp of account signup date
								companyName: this.organizationName, // Account's name
							});
						}
						console.info("AppCues initialized");
					} catch (err) {
						console.error("Error initializing AppCues");
					}
				}
			},
			storeUser() {
				localStorage.setItem("ls_user", JSON.stringify(this.me.user));
			},
			attributesEnabled() {
				// if the feature is enabled, show the attributes
				if (
					this.me &&
					this.me.organization.OrganizationFeatures &&
					this.me.organization.OrganizationFeatures.find((f) => {
						return f.featureName == constants.FEATURES.ATTRIBUTES;
					})
				) {
					return true;
				}
				// otherwise, fall back to the config
				if (window.env.VITE_ENABLE_ATTRIBUTES == "false") {
					return false;
				}
				return true;
			},
			marketplaceEnabled() {
				// if the feature is enabled, show the marketplace
				if (
					this.me &&
					this.me.organization.OrganizationFeatures &&
					this.me.organization.OrganizationFeatures.find((f) => {
						return f.featureName == constants.FEATURES.MARKETPLACE;
					})
				) {
					return true;
				}
				// otherwise, fall back to the config
				if (window.env.VITE_ENABLE_ATTRIBUTES == "false") {
					return false;
				}
				return true;
			},
			async connectAccount(href) {
				let width = 600;
				let height = 600;
				let top = window.outerHeight / 2 - height / 2;
				let left = window.outerWidth / 2 - width / 2;
				let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=${top}`;
				let w = window.open(href, "sso", params);
				let interval = window.setInterval(() => {
					try {
						let hash = w.location.hash;
						if (hash.indexOf("ls-contact-done") > -1) {
							if (hash.indexOf("-error") > -1) {
								const errorDescription = w.location.href
									.substring(w.location.href.indexOf("?") + 1)
									.split("&")
									.find((x) => x.startsWith("error_description"))
									.split("=")[1];
								const cleanErrorDescription = decodeURIComponent(errorDescription).replace(/\+/g, " ");
								this.pluginInstallation.text =
									"Failed to connect your account! The response was: " + cleanErrorDescription;
								this.pluginInstallation.showAction = true;
							}
							if (hash.indexOf("-success") > -1) {
								this.pluginInstallation.text = "You have successfully connected your account!";
								this.pluginInstallation.showAction = false;
								this.pluginInstallation.timeout = 3500;
							}
							w.close();
							window.clearInterval(interval);
						}
					} catch (e) {
						//console.log(e)
					}
				}, 250);
			},
			getSubscriptionStatus(applicationId, applications) {
				const obj = JSON.parse(applications);
				return obj[applicationId][0].linkStatus;
			},
			possiblyShowPluginInstallations() {
				if (!this.me.pluginComponentInstallations) {
					return;
				}
				// we do these one at a time
				let needsAuthorization = null;
				for (let i = 0; i < this.me.pluginComponentInstallations.length; i++) {
					let installation = this.me.pluginComponentInstallations[0];
					if (installation.PluginComponentInstallationAccountOAuths.length > 0) {
						// authorized, check the next one
						continue;
					} else {
						// needs more info
						needsAuthorization = installation;
						break;
					}
				}

				if (!needsAuthorization) {
					return;
				}

				// ok, we have one that needs auth. get the details on how to perform the auth
				let component = needsAuthorization.PluginComponent;
				let plugin = component.Plugin;
				let oauth = component.configuration.oauth;
				let state = {};
				oauth.state.forEach((s) => {
					state[s.name] = s.value;
				});
				//FUTURE: support for other auth types
				let callbackUrl =
					"https://" + window.location.host + "/plugin/callback.html?plugin=" + plugin.providerName;
				let href = parser.parse(oauth.authorizeUrl, {
					redirectUri: encodeURIComponent(callbackUrl),
					clientId: encodeURIComponent(oauth.clientId),
					scope: encodeURIComponent(oauth.scope.join(" ")),
					// FUTURE: s.value could be more than just a string...is that a problem?
					state: encodeURIComponent(JSON.stringify(state)),
				});
				this.pluginInstallation.text = `Your organization has activated the ${component.displayName} plugin!`;
				this.pluginInstallation.href = href;

				this.pluginInstallation.visible = true;
			},
			nav: function (url) {
				return this.$router.push(url);
			},
			showHelp: function (dialog) {
				if (dialog) {
					this.$refs.contactSupportDialog.open();
				} else {
					window.open("https://www.liveswitch.com/contact/getting-started-guide");
				}
			},
			showPrivacyPolicy: function () {
				window.open("https://www.liveswitch.com/legal-privacy-policy");
			},
			shouldShow: function (sectionName) {
				// no account = show nothing
				if (!this.account) {
					return false;
				}

				let hasPermission = false;
				if (sectionName === "") {
					// basically for the "show all" home only
					hasPermission = true;
				} else {
					// can they read this entity?
					hasPermission = this.account.hasPermissionTo("READ_" + sectionName);
				}
				// make sure we have an organization - otherwise we're still setting up
				const hasOrganization = this.anyOrganizations();

				// HACK ALERT!!!
				// this is garbage, do not ever ever ever do this
				// we killed the inbound functionality for new customers,
				// so all legacy customers got this flag set
				// and they are the only ones that get to see the "website" section, for inbound
				// message handling
				if (hasOrganization && sectionName === "WEBSITE" && !this.me.organization.supportsInboundMessaging) {
					hasPermission = false;
				}

				return hasPermission && hasOrganization;
			},
			showBottomNav() {
				return this.$vuetify.breakpoint.smAndDown;
			},
			showLeftNav() {
				return !this.showBottomNav();
			},
			showReferralPage() {
				window.open("https://www.liveswitch.com/referral-program");
			},
			logout: function () {
				this.$auth.logout({
					returnTo: window.location.protocol + "//" + window.location.host + "/logout",
				});
			},
			authenticated: function () {
				return this.$auth.isAuthenticated();
			},
			blockedUser: function () {
				return this.$auth.isBlocked();
			},
			multipleOrganizations: function () {
				return this.organizations().length > 1;
			},
			anyOrganizations: function () {
				return this.organizations().length > 0;
			},
			organizations: function () {
				return this.me && this.me.organizations ? this.me.organizations : [];
			},
			switchToOrg: function (org) {
				api.setOrganizationId(org.id);
				//StorageWrapper.setItem("org", JSON.stringify(org));
				this.$auth.login({
					organizationId: org.id,
					path: window.location.path,
				});
				//window.location.href = "/";
			},
			fabChanged: function (opened) {
				this.isFABOpen = opened;
			},
			navigateToSubscription() {
				if (this.isSubscriptionActive) {
					window.open(window.env.VITE_STRIPE_URL, "_blank");
				} else {
					return this.subscriptionUrl;
				}
			},
		},
		computed: {
			displayName() {
				if (this.me && this.me.user) {
					if (this.me.user.firstname) {
						return `${this.me.user.firstname}  ${this.me.user.lastname}`;
					}
					return this.me.user.email;
				}
				return "-";
			},
			userInitials() {
				let name = [];
				if (this.me && this.me.user && this.me.user.firstname) {
					name.push(this.me.user.firstname.substring(0, 1));
				}
				if (this.me && this.me.user && this.me.user.lastname) {
					name.push(this.me.user.lastname.substring(0, 1));
				}
				if (this.me && this.me.user && !this.me.user.firstname && !this.me.user.lastname) {
					name.push(this.me.user.email.split("@")[0].substring(0, 2));
				}
				return name.join("");
			},
			areButtonsSticky() {
				return (
					this.$route.name === "conversation" ||
					this.$route.name === "contact" ||
					this.$route.name === "organization" ||
					this.$route.name === "user"
				);
			},
			profileItems() {
				let profileItems = [
					{
						icon: "icon-users-3",
						text: "Users",
						permission: "USER",
						to: () => "/users",
						shouldShow: () => {
							if (
								!isRoleOrInRoles(this.me?.account.roles[0].name, [
									Role.Administrator,
									Role.SuperAdministrator,
									Role.Support,
								])
							) {
								return false;
							}
							return true;
						},
					},
					{
						icon: "icon-cog-gear",
						text: "Settings",
						permission: "ORGANIZATION",
						to: () => this.organizationUrl,
						shouldShow: () => {
							return true;
						},
					},
					{
						icon: "icon-credit-card",
						text: "Payment Settings",
						permission: "ORGANIZATION", //TODO: fix this, should be a specific subscription permission
						to: this.navigateToSubscription,
						shouldShow: () => {
							if (window.env.VITE_ENABLE_PURCHASE_MODULE == "false") {
								return false;
							}
							// for now, we only show payment options to users who started w a trial
							if (this.me?.account.roles[0].name.toLowerCase() === "user") {
								return false;
							}
							return this.me?.organization.isTrial;
						},
					},
					{
						icon: "icon-building-exit",
						text: "Switch Organization",
						subtext: this.organizationName,
						permission: "",
						to: () => "",
						shouldShow: function () {
							// permissions are separate
							return true;
						},
						subItems: this.organizations(),
					},
				];

				return profileItems;
			},
			navigationItems() {
				return [
					{
						text: "Home",
						icon: "icon-home-solid",
						mobileIcon: "icon-home-stroke",
						mobileEnabled: true,
						to: "/",
						permission: "",
					},
					{
						text: "Websites",
						icon: "icon-globe-website-stroke",
						mobileIcon: "icon-globe-website-stroke",
						mobileEnabled: false,
						to: "/websites",
						permission: "WEBSITE",
					},
					{
						text: "Sessions",
						icon: "icon-conversation-solid",
						mobileIcon: "icon-conversation-stroke",
						mobileEnabled: true,
						to: "/conversations",
						permission: "CONVERSATION", // prev name
					},
					{
						text: "Projects",
						icon: "icon-form",
						mobileIcon: "icon-form",
						mobileEnabled: true,
						to: "/projects",
						permission: "PROJECT",
					},
					{
						text: "Contacts",
						icon: "icon-contacts-stroke",
						mobileIcon: "icon-contacts-stroke",
						mobileEnabled: true,
						to: "/contacts",
						permission: "CUSTOMER",
					},
					{
						text: "Users",
						icon: "icon-users-3",
						mobileIcon: "icon-users-3",
						mobileEnabled: false,
						permission: "USER",
						to: "/users",
						shouldShow: () => {
							return false;
						},
					},
					{
						text: "Settings",
						icon: "icon-cog-gear",
						mobileIcon: "icon-cog-gear",
						mobileEnabled: false,
						permission: "ORGANIZATION",
						to: this.organizationUrl,
						shouldShow: () => {
							return false;
						},
					},
				].filter((x) => this.shouldShow(x.permission));
			},
			organizationName() {
				if (this && this.me && this.me.organization) {
					return this.me.organization.name;
				}
				return "";
			},
			organizationUrl() {
				if (this && this.me && this.me.organization) {
					return `/organizations/${this.me.organization.id}`;
				}
				return "";
			},
			subscriptionUrl() {
				if (this && this.me && this.me.organization) {
					return `/subscriptions/${this.me.organization.id}`;
				}
				return "";
			},
			mini() {
				return this.$vuetify.breakpoint.smAndDown;
			},
		},
	};
</script>

<style scoped>
	.v-avatar {
		background-color: #3b7af8;
		color: white;
		font-size: x-small !important;
		font-family: "Inter Tight";
	}
	nav .v-icon {
		padding-right: 8px;
		font-size: 18px;
	}
	.action {
		cursor: pointer;
	}
	.main {
		/*background-image: url('@/assets/supbubble-dashboard-home-bg.webp');
  background-size:cover;
  background-position:center;*/
		background-color: #f5f5f5;
		/* padding-left: 200px !important; */
	}

	.v-list-item {
		margin: 0 8px;
		padding: 0 8px;
		border-radius: 8px;
	}

	.v-list a.v-list-item--active {
		color: inherit;
	}

	.v-list-item--link:before {
		background-color: transparent;
	}
	.v-list-item.v-list-item--active {
		background-color: var(--v-accent-base);
	}

	.profile-menu {
		margin-top: 12px;
		contain: initial;
		overflow: visible;
		border-radius: 8px;
	}
	.profile-menu .v-list,
	.profile-menu-submenu,
	.profile-menu-submenu .v-list {
		border-radius: 8px;
	}
	.profile-menu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
		margin: 0px;
		border-radius: 0px;
	}

	.profile-menu-submenu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
		margin: 0px;
		padding-left: 15px;
		border-radius: 0px;
	}

	.profile-menu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover,
	.profile-menu-submenu .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
		background-color: #eff3fd;
	}

	.v-list-item a {
		text-decoration: none;
		color: inherit;
	}

	.container.shell.bottom-back-buttons::v-deep .sticky-actions > button:first-child {
		border: 1px solid #e0e0e0 !important;
	}

	.menu-footer {
		display: flex;
		flex-direction: row;
	}

	.menu-footer-links {
		display: flex;
		justify-content: space-around;
		padding-top: 8px;
	}

	.menu-footer-links button.v-btn {
		padding: 0 8px;
		font-size: 12px;
		height: 28px;
		border-radius: 4px;
		background-color: #fff;
	}

	.menu-footer-links button.v-btn:hover {
		background-color: #eff3fd;
	}
	.menu-footer-links button.v-btn:before {
		background-color: #fff;
	}

	.v-card {
		text-align: left !important;
	}

	.menu-footer > .v-list-item.v-list-item--link.theme--light,
	.menu-footer > .v-list-item.v-list-item--link.theme--light > .v-list-item__icon > i {
		color: rgba(45, 116, 255, 1);
	}

	.v-application::v-deep .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
		color: #fff !important;
	}

	.v-application::v-deep .v-input--switch--inset.v-input--is-dirty .v-input--switch__track {
		color: #2d74ff !important;
		opacity: 1;
	}

	.v-application::v-deep
		.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
		> .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
		.v-slide-group__prev {
		display: none;
	}

	.not-allowed-profile-item {
		background-color: rgb(3, 1, 29, 0.08);
		pointer-events: none;
	}

	.not-allowed-profile-item::v-deep .v-list-item__title,
	.not-allowed-profile-item::v-deep .v-list-item__icon i {
		opacity: 0.3;
	}

	.progress-icon:hover::before,
	.progress-icon:focus::before {
		opacity: 0 !important;
	}

	@media (max-width: 959px) {
		.container.shell.bottom-back-buttons {
			background-color: #f5f5f5;
		}

		.container.shell.bottom-back-buttons:has(.sticky-actions) {
			padding-bottom: 56px;
		}

		.container.shell.bottom-back-buttons::v-deep .sticky-actions {
			position: fixed;
			bottom: 55px;
			display: flex;
			justify-content: space-evenly;
			margin-left: -28px;
			width: 100%;
			background-color: white;
			box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px -4px 5px 0px rgba(0, 0, 0, 0.14);
			z-index: 5;
		}

		.container.shell.bottom-back-buttons::v-deep .sticky-actions > button {
			width: 35%;
		}

		.container.shell.bottom-back-buttons::v-deep:has(.contact-page) {
			padding-left: 0px;
			padding-right: 0px;
		}
		.container.shell.bottom-back-buttons::v-deep:has(.artifact-page) {
			background-color: #fff;
		}
		.container.shell::v-deep:has(.artifact-page) {
			background-color: #fff;
		}
	}

	.embeded .container.shell {
		padding: 0;
		border-radius: 0;
	}
	.embeded .container.shell .page {
		border-radius: 0;
	}
</style>
<style lang="scss" scoped>
	.maintenance::v-deep .v-snack__wrapper {
		border-left-width: 8px;
		border-left-style: solid;
	}

	.maintenance::v-deep .v-snack__wrapper h3 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.maintenance::v-deep .v-snack__wrapper h3 > button {
		margin-top: -10px;
		margin-right: -10px;
		font-size: 12px;
	}

	.maintenance::v-deep .v-snack__wrapper.primary h3 > button {
		color: #0070ff !important;
	}
	.maintenance::v-deep .v-snack__wrapper.error h3 > button {
		color: #e84867 !important;
	}

	.maintenance::v-deep .v-snack__wrapper.primary {
		border-left-color: #0070ff !important;
		background-color: #e8f1fe !important;
		color: #0070ff !important;
	}

	.maintenance::v-deep .v-snack__wrapper.error {
		border-left-color: #e84867 !important;
		background-color: #faeef0 !important;
		color: #e84867 !important;
	}

	.container.shell {
		padding-top: 0;
	}

	@media (min-width: 320px) and (max-width: 959px) and (orientation: landscape) {
		header::v-deep > .v-toolbar__content {
			padding-left: max(env(safe-area-inset-left, 48px), 16px);
			padding-right: max(env(safe-area-inset-right, 48px), 16px);
		}
		.container.shell {
			border-radius: 0px;
			padding-left: max(calc(env(safe-area-inset-left) - 12px), 12px);
			padding-right: max(calc(env(safe-area-inset-right) - 12px), 12px);
		}
	}

	.v-system-bar.subscription {
		text-align: center;
		text-transform: uppercase;
		background-color: var(--v-primary-base);
		height: 52px !important;
		z-index: 5;
	}

	@media (max-width: 600px) {
		.v-system-bar.subscription {
			font-size: 0.6rem;
		}
	}

	.profile-menu-submenu {
		max-width: 250px;
	}

	.avatar {
		font-size: 1rem !important;
	}

	.profile-user-details:hover {
		background-color: #fff !important;
	}

	.profile-name {
		font-size: small;
		max-width: 150px !important;
		padding-top: 3px;
	}
</style>
<style>
#app.embeded .project-page .add-item-button,
#app.embeded .conversation-page .add-item-button{
	display: none !important;
}
#app.embeded .project-page .page-actions,
#app.embeded .conversation-page .page-actions{
	display: none !important;
}
#app.embeded #fab-button{
	display: none !important;
}
</style>