import uuid from "../helpers/uuid";
import * as Sentry from "@sentry/browser";
import StorageWrapper from "@/helpers/storage";

let api = {
	_token: null,
	_platformIdentityToken: null,
	_basePath: "",
	_events: {},
	_usedRoles: ["Administrator", "User", "View Only"],

	setToken: function (value) {
		this._token = value;
	},
	setPlatformIdentityToken: function (value) {
		this._platformIdentityToken = value;
	},
	setOrganizationId: function (id) {
		StorageWrapper.setItem("organization_id", id);
	},
	getOrganizationId: function () {
		return StorageWrapper.getItem("organization_id") ?? "";
	},
	on: function (eventName, fn) {
		this._events[eventName] = this._events[eventName] || [];
		this._events[eventName].push(fn);
	},
	fire: function (eventName, args) {
		let evts = this._events[eventName];
		if (!evts) {
			return;
		}
		for (var i = 0; i < evts.length; i++) {
			evts[i](args);
		}
	},
	_fetch: async function (url, options) {
		try {
			return await fetch(url, options);
		} catch (e) {
			// network errors are almost certainly the culprit
			// and if so, give the user something nicer to see
			if (
				e.message.toLowerCase().indexOf("failed to fetch") > -1 ||
				e.message.toLowerCase().indexOf("networkerror when attempting to fetch") > -1
			) {
				e.message = "We were unable to contact the server. Please check your network and try again.";
			}
			throw e;
		}
	},
	post: async function (path, data) {
		let r = await this._fetch(
			this.buildUrl(path),
			this.buildHeaders({
				method: "POST",
				body: JSON.stringify(data),
			})
		);
		return this._handleResponse(r);
	},
	get: async function (path) {
		let r = await this._fetch(
			this.buildUrl(path),
			this.buildHeaders({
				method: "GET",
			})
		);
		return this._handleResponse(r);
	},
	getToken: function () {
		return this._token;
	},
	getProjectInsecure: async function (projectId, shareId) {
		let r = await this._fetch(this.buildUrl(`/projects/${projectId}/public/${shareId}`), {
			method: "GET",
		});
		return this._handleResponse(r);
	},
	getArtifactsForProjectInsecure: async function (id, artifactType) {
		let r = await this._fetch(this.buildUrl(`/projects/${id}/public/artifacts?artifactType=${artifactType}`), {
			method: "GET",
		});
		return this._handleResponse(r);
	},
	getRecordingsForProject: async function (id) {
		let r = await this._fetch(this.buildUrl(`/projects/${id}/recordings`), {
			method: "GET",
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	getSnapshotsForProject: async function (id) {
		let r = await this._fetch(this.buildUrl(`/projects/${id}/snapshots`), {
			method: "GET",
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	getFilesForProject: async function (id) {
		let r = await this._fetch(this.buildUrl(`/projects/${id}/files`), {
			method: "GET",
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	getPlatformIdentityToken: function () {
		return this._platformIdentityToken;
	},
	signupUser: async function () {
		const userId = StorageWrapper.getItem("userId") || "";
		const user = userId !== "" ? { id: userId } : {};
		return this.post(`/signup/user`, user);
	},
	signupOrganization: async function (org) {
		return this.post(`/signup/organization`, org);
	},
	sendInvitation: async function (conversationId) {
		return this.post(`/conversations/sendInvitation`, {
			conversationId: conversationId,
		});
	},
	shareConversation: async function (request) {
		return this.post(`/conversations/share`, request);
	},
	getRoles: async function () {
		const roles = await this.get(`/authorization/roles`);
		return roles.filter((x) => this._usedRoles.includes(x.name));
	},
	getConversationsStatuses: async function (ids) {
		const conversations = await this.get(`/conversations/status/${ids}`);
		return conversations;
	},
	completeSubscription: async function (subscription) {
		return this.post(`/subscriptions/complete`, subscription);
	},
	createRecordingToken: async function ({ meetingId, recordingId, conversationId }) {
		let r = await this._fetch(this.buildUrl(`/recordings/token`), {
			method: "POST",
			body: JSON.stringify({
				meetingId: meetingId,
				recordingId: recordingId,
				conversationId: conversationId,
			}),
			headers: {
				"Content-Type": "application/json",
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	createPlatformIdentity: async function (userId) {
		const response = await this._fetch(this.buildUrl(`/tokens/platform`), {
			method: "POST",
			body: JSON.stringify({ userId: userId }),
			headers: {
				"Content-Type": "application/json",
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(response);
	},
	getRecordingFromToken: async function (token) {
		return this.get(`/public/recordings/${token}`);
	},
	getRecordings: async function (conversationId) {
		let r = await this._fetch(this.buildUrl(`/recordings/${conversationId}`), {
			method: "GET",
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	getHomeDashboard: async function () {
		let r = await this._fetch(this.buildUrl(`/home/dashboard`), {
			method: "GET",
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	getDefaultForm: async function () {
		const response = await this.get(`/forms/default/layout`);
		return response;
	},
	submitCustomWebForm: async function (form) {
		const response = await this.post("/form-submissions/public", form);
		return response;
	},
	deleteRecording: async function (conversationId, recordingId) {
		let r = await this._fetch(this.buildUrl(`/recordings/${conversationId}/${recordingId}`), {
			method: "DELETE",
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	getRecordingShareUrl: async function ({ meetingId, recordingId, conversationId }) {
		const response = await this._fetch(
			this.buildUrl(
				`/recordings/share/url?conversationId=${conversationId}&meetingId=${meetingId}&recordingId=${recordingId}`
			),
			{
				method: "GET",
				headers: {
					"x-organization-id": `${this.getOrganizationId()}`,
					authorization: `Bearer ${this.getToken()}`,
				},
			}
		);
		return this._handleResponse(response);
	},
	uploadSnapshot: async function (conversationId, blob, parentId, metadata) {
		// Create form data and add the blob to it.
		let formData = new FormData();
		formData.append("snapshot", blob);

		let url = `/public/snapshots/${conversationId}?`;
		if (parentId) {
			url += `&parentId=${parentId}`;
		}
		if (metadata) {
			url += `&metadata=${JSON.stringify(metadata)}`;
		}
		// Send an HTTP POST request with the given form data as its body.
		let response = await this._fetch(this.buildUrl(url), {
			method: "POST",
			body: formData,
		});
		return this._handleResponse(response);
	},
	uploadFiles: async function (conversationId, file, parentId, metadata) {
		// Create form data and add the blob to it.
		let formData = new FormData();
		formData.append("file", file);

		let url = `/public/upload/${conversationId}?`;
		if (parentId) {
			url += `&parentId=${parentId}`;
		}
		if (metadata) {
			url += `&metadata=${JSON.stringify(metadata)}`;
		}

		url += `&sessionId=${uuid.uuidv4()}&recordingId=${uuid.uuidv4()}`;
		// Send an HTTP POST request with the given form data as its body.
		let response = await this._fetch(this.buildUrl(url), {
			method: "POST",
			body: formData,
		});
		return this._handleResponse(response);
	},
	deleteOrganizationLogo: async function (organizationId) {
		let r = await this._fetch(
			this.buildUrl(`/organizations/${organizationId}/logo`),
			this.buildHeaders({
				method: "DELETE",
			})
		);
		return this._handleResponse(r);
	},
	deleteOrganizationVirtualBackground: async function (organizationId) {
		let r = await this._fetch(
			this.buildUrl(`/organizations/${organizationId}/background`),
			this.buildHeaders({
				method: "DELETE",
			})
		);
		return this._handleResponse(r);
	},
	updateOrganizationLogo: async function (organizationId, file) {
		let formData = new FormData();

		formData.append("logo", file);
		let r = await this._fetch(this.buildUrl(`/organizations/${organizationId}/logo`), {
			method: "POST",
			body: formData,
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	updateOrganizationVirtualBackground: async function (organizationId, file) {
		let formData = new FormData();

		formData.append("virtualBackground", file);
		let r = await this._fetch(this.buildUrl(`/organizations/${organizationId}/background`), {
			method: "POST",
			body: formData,
			headers: {
				"x-organization-id": `${this.getOrganizationId()}`,
				authorization: `Bearer ${this.getToken()}`,
			},
		});
		return this._handleResponse(r);
	},
	getVirtualBackgrounds: async function (orgId) {
		return this.get(`/organizations/${orgId}/virtual-backgrounds`);
	},
	getSharingUsersForProject: async function (projectId) {
		return this.get(`/users/project/${projectId}`);
	},
	getSharingUsers: async function (args, warnings) {
		let url = `/users/sharing`;
		if (arguments.length > 0) {
			let wherejson = encodeURIComponent(JSON.stringify(args.where || {}));
			let orderjson = encodeURIComponent(JSON.stringify(args.order || []));
			let page = args.page || 1;
			let pageSize = args.pageSize || 10;
			url += `?page=${page}&pageSize=${pageSize}&where=${wherejson}&order=${orderjson}`;
		}
		let r = await this._fetch(this.buildUrl(url), this.buildHeaders());
		return this._handleResponse(r, warnings || []);
	},
	getMe: async function (includePermissions, includePlugins, includeSubscription) {
		includePermissions = !!includePermissions;
		includePlugins = !!includePlugins;
		//includeSubscription = !!includeSubscription;
		includeSubscription = false;
		let r = await this._fetch(
			this.buildUrl(
				`/me?includePermissions=${includePermissions}&includePlugins=${includePlugins}&includeSubscription=${includeSubscription}`
			),
			this.buildHeaders()
		);
		return this._handleResponse(r);
	},
	setMe: async function () {
		let r = await this._fetch(
			this.buildUrl(`/me`),
			this.buildHeaders({
				method: "POST",
				body: JSON.stringify({}),
			})
		);
		return this._handleResponse(r);
	},
	createBrowserIdToken: async function (browserId) {
		return this.post(`/tokens/token`, { browserId: browserId });
	},
	submitSupportRequest: async function (message) {
		return this.post(`/me/support`, { message: message });
	},
	createShortenedUrl: async function (url, expiration = 30) {
		let r = await this._fetch(
			this.buildUrl(`/s?expiration=` + expiration),
			this.buildHeaders({
				method: "POST",
				body: JSON.stringify(url),
			})
		);
		return this._handleResponse(r);
	},
	buildUrl: function (path, query) {
		if (!path.indexOf("/") === 0) {
			path = "/" + path;
		}
		let domain = window.env.VITE_API_ROOT;
		if (domain.endsWith("/")) {
			domain = domain.substring(0, domain.length - 1);
		}
		let separator = "";
		if (path.indexOf("?") == -1) {
			separator = "?";
		} else {
			separator = "&";
		}
		path += separator;
		if (query) {
			let params = [];
			for (let q in query) {
				if (query.hasOwnProperty(q)) {
					let v = query[q];
					params.push(encodeURIComponent(q) + "=" + encodeURIComponent(v));
				}
			}
			path += params.join("&");
			path += "&";
		}
		path += "_cacheBust=" + new Date().getTime();
		const final = domain + this._basePath + path;
		return final;
	},
	buildHeaders: function (obj) {
		return {
			...{
				headers: {
					"Content-Type": "application/json",
					"x-organization-id": `${this.getOrganizationId()}`,
					authorization: `Bearer ${this.getToken()}`,
				},
			},
			...obj,
		};
	},
	_handleResponse: async function (r, warnings) {
		if (r.ok) {
			// this occurs for 200-299 error codes
			let json = await r.json();
			if (r.headers.has("Warning")) {
				let parts = r.headers.get("Warning").split(";");
				for (var i = 0; i < parts.length; i++) {
					let words = parts[i].split(" ");
					let code = words.shift();
					let description = words.join(" ");
					warnings.push({ code: code, description: description });
				}
			}
			return json;
		} else {
			let t = null;
			const contentType = r?.headers?.get("Content-Type"); // Case insenstive.
			if (contentType && contentType.indexOf("application/json") > -1) {
				t = await r.json();
			} else {
				t = {
					message: await r.text(),
				};
			}
			t.code = r.status;
			Sentry.captureMessage(`Http Error: StatusCode=${t.code}, Url=${r.url}, Message: ${t.message}`, "error");
			this.fire("error", t);
			return Promise.reject(t);
		}
	},
};

let entities = [
	{
		singular: "Account",
		plural: "Accounts",
		path: "/accounts",
	},
	{
		singular: "Appointment",
		plural: "Appointments",
		path: "/appointments",
	},
	{
		singular: "User",
		plural: "Users",
		path: "/users",
	},
	{
		singular: "Organization",
		plural: "Organizations",
		path: "/organizations",
	},
	{
		singular: "Website",
		plural: "Websites",
		path: "/websites",
	},
	{
		singular: "Conversation",
		plural: "Conversations",
		path: "/conversations",
	},
	{
		singular: "Project",
		plural: "Projects",
		path: "/projects",
	},
	{
		singular: "ProjectShare",
		plural: "ProjectShares",
		path: "/project-share",
	},
	{
		singular: "ConversationView",
		plural: "ConversationViews",
		path: "/conversation-views",
	},
	{
		singular: "Customer",
		plural: "Customers",
		path: "/customers",
	},
	{
		singular: "CustomerView",
		plural: "CustomerViews",
		path: "/customer-views",
	},
	{
		singular: "ProjectShareView",
		plural: "ProjectShareViews",
		path: "/project-share-views",
	},
	{
		singular: "Form",
		plural: "Forms",
		path: "/forms",
	},
	{
		singular: "Plugin",
		plural: "Plugins",
		path: "/plugins",
	},
	{
		singular: "PluginInstallation",
		plural: "PluginInstallations",
		path: "/plugin-installations",
	},
	{
		singular: "AsyncForm",
		plural: "AsyncForms",
		path: "/async-forms",
	},
	{
		singular: "Snapshot",
		plural: "Snapshots",
		path: "/snapshots",
	},
	{
		singular: "Subscription",
		plural: "Subscriptions",
		path: "/subscriptions",
	},
	{
		singular: "Product",
		plural: "Products",
		path: "/products",
	},
	{
		singular: "Label",
		plural: "Labels",
		path: "/labels",
	},
	{
		singular: "EntityAttribute",
		plural: "EntityAttributes",
		path: "/entity-attributes",
	},
	{
		singular: "Attribute",
		plural: "Attributes",
		path: "/attributes",
	},
	{
		singular: "AttributeGroup",
		plural: "AttributeGroups",
		path: "/attribute-groups",
	},
	{
		singular: "File",
		plural: "Files",
		path: "/files",
	},
];
for (let i = 0; i < entities.length; i++) {
	let entity = entities[i];
	api["get" + entity.plural] = async function (args, warnings) {
		let url = `${entity.path}`;
		if (args && args.urlModifier) {
			url = args.urlModifier(url);
		}
		if (arguments.length > 0) {
			let wherejson = encodeURIComponent(JSON.stringify(args.where || {}));
			let orderjson = encodeURIComponent(JSON.stringify(args.order || []));
			let page = args.page || 1;
			let pageSize = args.pageSize || 10;
			url += `?page=${page}&pageSize=${pageSize}&where=${wherejson}&order=${orderjson}`;
		}
		let r = await this._fetch(this.buildUrl(url), this.buildHeaders());
		return this._handleResponse(r, warnings || []);
	};
	api["create" + entity.singular] = async function (body, warnings) {
		delete body.id;
		Sentry.setContext("createBody", body);
		let r = await this._fetch(
			this.buildUrl(`${entity.path}/`),
			this.buildHeaders({
				method: "POST",
				body: JSON.stringify(body),
			})
		);
		return this._handleResponse(r, warnings || []);
	};
	api["get" + entity.singular] = async function (id, warnings) {
		let r = await this._fetch(this.buildUrl(`${entity.path}/${id}`), this.buildHeaders());
		return this._handleResponse(r, warnings || []);
	};
	api["delete" + entity.singular] = async function (id, query, warnings) {
		let r = await this._fetch(
			this.buildUrl(`${entity.path}/${id}`, query),
			this.buildHeaders({
				method: "DELETE",
			})
		);
		return this._handleResponse(r, warnings || []);
	};
	api["update" + entity.singular] = async function (id, body, warnings) {
		delete body.id;
		Sentry.setContext("updateBody", body);
		let r = await this._fetch(
			this.buildUrl(`${entity.path}/${id}`),
			this.buildHeaders({
				method: "PUT",
				body: JSON.stringify(body),
			})
		);
		return this._handleResponse(r, warnings || []);
	};
}

export default api;
