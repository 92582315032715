<template>
	<v-system-bar class="subscription" app>
		<span class="inner">
			<v-icon color="white">icon-info-circle-stroke</v-icon>
			<b>
				<span v-if="isEndedTrial && canUpdateOrg"
					><a @click="$emit('upgrade')">Upgrade</a> your subscription to access all features.
				</span>
				<span v-else-if="isEndedTrial && !canUpdateOrg"
					>Contact an administrator to upgrade your subscription to access all features.
				</span>
				<span v-else-if="isTrialCCNow && canUpdateOrg">
					Welcome! <a @click="$emit('upgrade')">Select your plan</a> and follow the prompts to start your free
					trial.
				</span>
				<span v-else-if="isTrialCCNow && !canUpdateOrg"
					>Welcome! Contact an administrator to select your plan to start your free trial.
				</span>
				<span v-else-if="trialDaysRemaining > -1 && canUpdateOrg">
					{{ trialDaysRemaining > 1 ? `${trialDaysRemaining} Days` : "1 Day" }} remaining on your free trial.
					Ready for the full experience?
					<a @click="$emit('upgrade')">Upgrade now</a>
				</span>
				<span v-else-if="trialDaysRemaining > -1 && !canUpdateOrg">
					{{ trialDaysRemaining > 1 ? `${trialDaysRemaining} Days` : "1 Day" }} remaining on your free trial.
					Ready for the full experience? Contact an administrator to upgrade your subscription.
				</span>
			</b>
		</span>
	</v-system-bar>
</template>
<script>
	import Account from "../api/account";
	import api from "@/api";
	import StorageWrapper from "@/helpers/storage";
	
	export default {
		name: "TrialTopBar",
		props: {
			isEndedTrial: Boolean,
			isTrialCCNow: Boolean,
			trialDaysRemaining: Number,
		},
		data() {
			return {
				canUpdateOrg: false,
			};
		},
		async mounted() {
			let cachedAccount;
			try {
				cachedAccount = JSON.parse(StorageWrapper.getItem("account"));
			} catch (e) {}
			if (!cachedAccount) {
				const me = await api.getMe(true, true, true);
				cachedAccount = me.account;
			}
			const account = new Account(cachedAccount);
			this.canUpdateOrg = account.hasPermissionTo("UPDATE_ORGANIZATION");
		},
	};
</script>
<style scoped>
	.v-system-bar.subscription span {
		margin: auto;
	}
	.v-system-bar.subscription span,
	.v-system-bar.subscription span a {
		color: white;
	}

	.v-system-bar.subscription span a {
		text-decoration: underline;
	}
</style>
